body,html {
  min-height: 100%; }

body {
  background: #47017F;
  background: #340559;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  position: relative;
  &::after {
    content: '';
    display: block;
    height: 50px; } }

h1,h2,h3,h4,h5,h6 {
  text-align: center; }

.header {
  text-align: center;
  box-sizing: border-box;
  padding: 25px 15px;
  background: linear-gradient(#6B27A2, #340559);
  color: white;
  font-size: 1.4em;
  position: relative;
  h1 {
    font-family: 'Oleo Script', cursive;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  h2 {
    font-size: 1.2em; }
  p {
    font-size: 16px;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 5px;
    font-family: Georgia;
    line-height: 1.5;
    color: #fce5fc; } }

#changelog {
  text-align: left;

  .changelog-text {
    text-transform: uppercase;
    font-size: 0.6em;
    position: absolute;
    left: 15px;
    top: 15px;
    .changelog-link {
      color: white;
      text-decoration: none; }
    .changelog-box {
      display: none;
      overflow: auto;
      margin-top: 13px;
      padding: 10px;
      border-radius: 5px;
      background-color: #2c0f3d;
      width: 300px;
      height: 300px;
      max-width: calc(100vw - 50px);
      p {
        font-family: 'Roboto' !important;
        font-size: 0.9em;
        text-transform: none; }
      a {
        color: #2adef9; } } } }

.page {
  padding: 10px 15px;
  padding-bottom: 0;
  max-width: 750px;
  margin: 0 auto;

  h3 {
    font-weight: 900;
    font-size: 1.5em;
    margin-top: 45px;
    margin-bottom: 25px;
    font-weight: 600; }
  h4 {
    font-weight: 500; }

  p {
    text-align: center;
    line-height: 1.4; }
  code.yellow {
    color: #ffe942; }
  pre {
    font-family: 'Roboto Mono', monospace;
    overflow-x: auto;
    padding: 15px;
    border-radius: 5px;
    line-height: 1.6;
    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline; } }
    &.terminal {
      color: #F2F2F2;
      text-align: center;
      background: #3f1065;
      border: 15px solid #56159c;
      font-size: .9em;
      padding: 40px 10px;
      code::before {
        content: "$ ";
        color: #be29ec; } } } }

.example-usage {
  margin: 15px 0 60px 0;
  pre, p {
    margin: 0; }
  pre figure {
    margin-top: 0;
    margin-bottom: 0; }
  a {
    color: white; } }

.code-header {
  font-size: 1em;
  padding: 15px;
  padding-bottom: 0;
  h1,h2 {
    text-align: left;
    margin: 0; }
  a {
    color: #be29ec; } }

.code-viewer {
  box-sizing: border-box;
  .highlight {
    padding: 15px; }
  .highlight, pre {
    margin: 0; } }

a.view-sanemacs-link {
  color: #ba65ff;
  text-align: center;
  width: 100%;
  display: block;
  text-decoration: none;
  &:hover {
    color: #cf94ff;
    text-decoration: underline; } }

.bonus-tip {
  margin-bottom: 60px; }

footer {
  font-size: .8em;
  font-family: 'Roboto Mono', monospace;
  position: absolute;
  right: 15px;
  bottom: 15px;
  @media screen and (max-width: 850px) {
    left: 0;
    width: 100%;
    text-align: center;
    bottom: 30px; }
  a {
    color: white; } }

hr {
  margin: 15px 0;
  color: #6a14b0; }

table {
  background: #7a22b5;
  width: 100%;
  font-size: 14px;
  border-collapse: collapse;
  margin-bottom: 60px;
  @media screen and (max-width: 500px) {
    font-size: 12px; } }

table td, table th {
  padding: 10px 20px;
  text-align: left;
  border: 0px solid white;
  border-right-width: 1px;
  border-bottom-width: 1px;
  margin: 0;
  @media screen and (max-width: 500px) {
    padding: 2px; } }

.github-button-container {
  width: 150px;
  position: absolute;
  right: 5px;
  top: 20px;
  @media screen and (max-width: 530px) {
    iframe {
      display: none; }
    #view-on-github {
      text-align: right !important;
      margin-right: 10px;
      margin-top: -7px !important; } }

  iframe, #view-on-github {
    opacity: 0.7;
    transition: 150ms ease-in;
    &:hover {
      opacity: 1; } }

  #view-on-github {
    text-align: left;
    display: block;
    color: white;
    font-family: 'Roboto', sans;
    font-size: 14px;
    margin-top: 3px;
    text-decoration: none;
    &:hover {
      text-decoration: underline; } } }



// RSS
#subscribe-button {
  padding: 5px;
  display: inline-block;
  margin-top: 15px;
  vertical-align: middle;
  text-decoration: none;
  font-size: 0.65em;

  color: orange;
  transition: 150ms ease-in-out;
  &:hover {
    color: #e68500; }

  svg, span {
    vertical-align: middle; }

  svg {
    width: 20px;
    height: 20px; } }


.page.wide {
  max-width: 1200px; }


.install-row {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 818px) {
    flex-direction: column;
    .install-col {
      max-width: 100%; } } }


.install-col {
  max-width: 50%;
  padding: 15px;
  box-sizing: border-box; }


.install-row h4 {
  font-size: 2em;
  color: #f2c3ff;
  margin-top: 0;
  line-height: 1.5; }
